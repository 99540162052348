export * from './declaration.service';
import { DeclarationService } from './declaration.service';
export * from './default.service';
import { DefaultService } from './default.service';
export * from './movement.service';
import { MovementService } from './movement.service';
export * from './organisation.service';
import { OrganisationService } from './organisation.service';
export * from './settings.service';
import { SettingsService } from './settings.service';
export const APIS = [DeclarationService, DefaultService, MovementService, OrganisationService, SettingsService];
