import { Injectable } from '@angular/core';
import { FeatureService as ApiFeatureService, ApplicationFeatures } from '@api/index';
import { ReplaySubject } from 'rxjs';


export namespace Feature {
  export type FeatureEnum = 't.use.tobb-und.guarantee'
    | 'companyinfo.business-profile'
    | 'companyinfo.business-profile.geocoordinates'
    | 'epd.support'
    | 'epd.print.cmr'
    | 'ui.epd.description'
    | 'customs_operations.enable.service_type.gda'
    | 'business.organisation.type.showAll'
    | 'view.draft.epd'
    | 'epd.draft.attachment'
    | 'send.enable.message_type.etir'
    | 'load.guarantee.details.disabled'
    | 'epd.cancel'
    | 't.atez.transcode.integration';

  export const FeatureEnum = {
    tUseTobbUndGuarantee: 't.use.tobb-und.guarantee' as FeatureEnum,
    BusinessProfileEnabled: 'companyinfo.business-profile' as FeatureEnum,
    BusinessProfileGeoCoordinatesEnabled: 'companyinfo.business-profile.geocoordinates' as FeatureEnum,
    EpdPrintCMR: 'epd.print.cmr' as FeatureEnum,
    UiEpdDescription: 'ui.epd.description' as FeatureEnum,
    Gda: 'customs_operations.enable.service_type.gda' as FeatureEnum,
    EtirAnnex11: 'send.enable.message_type.etir' as FeatureEnum,
    BusinessOrganisationTypesShowAll: 'business.organisation.type.showAll' as FeatureEnum,
    ViewDraftEPD: 'view.draft.epd' as FeatureEnum,
    EpdDraftAttachment: 'epd.draft.attachment' as FeatureEnum,
    LoadGuaranteeDetailsDisabled: 'load.guarantee.details.disabled' as FeatureEnum,
    EpdCancel: 'epd.cancel' as FeatureEnum,
    isAtezIntegrationEnabled: 't.atez.transcode.integration' as FeatureEnum
  };
}


@Injectable({
  providedIn: 'root'
})
export class FeatureService {

  private features: ReplaySubject<ApplicationFeatures>;

  constructor(private apiFeatureService: ApiFeatureService) { }

  public getFeatures(clearCache?: boolean): ReplaySubject<ApplicationFeatures> {
    if (this.features == null || clearCache) {
      this.features = new ReplaySubject<ApplicationFeatures>(1);
      this.apiFeatureService.getFeatures().subscribe(features => this.features.next(features));
    }

    return this.features;
  }

  public isFeatureEnabled(feature: Feature.FeatureEnum, clearCache?: boolean): Promise<boolean> {
    const promise = new Promise<boolean>(resolve => {
      this.getFeatures(clearCache).subscribe(features => {
        if (features != null && true === features[feature]) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });

    return promise;
  }
}
