<div>
  <h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>{{
    data.markdowns[displayMarkdownIndex].title }}&nbsp;</h1>
  <div mat-dialog-content>
    <ng-container *ngFor="let markdown of data.markdowns; let i = index">
      <markdown ngPreserveWhitespaces *ngIf="i === displayMarkdownIndex">
        {{ markdown.markdowns[data.localeCode].text }}
      </markdown>
    </ng-container>

  </div>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center">
  <button *ngIf="data.markdowns[displayMarkdownIndex].actions.ok" mat-raised-button mat-button type="button"
    color="primary" (click)="onOk()">{{
    data.markdowns[displayMarkdownIndex].actions.ok }}</button>
  <button *ngIf="data.markdowns[displayMarkdownIndex].actions.close" mat-raised-button mat-button type="button"
    color="primary" (click)="onClose()">{{
    data.markdowns[displayMarkdownIndex].actions.close }}</button>
  <button *ngIf="data.markdowns[displayMarkdownIndex].actions.logout" mat-raised-button mat-button type="button"
    (click)="onLogout()">{{
    data.markdowns[displayMarkdownIndex].actions.logout }}</button>
</div>