import { Injectable } from '@angular/core';
import { ApplicationMarkdownExtension, MarkdownService } from '@api/index';
import { MarkdownService as SharedMarkdownService } from '@app/shared/services/markdown.service';
import { Observable } from 'rxjs';
import { MarkdownServiceProperties } from '../models/markdown-service-properties';

@Injectable({
    providedIn: 'root'
})
export class DeclarationCountryMarkdownService implements SharedMarkdownService {

    constructor(
        private markdownService: MarkdownService) {
    }

    public getMarkdowns(properties: MarkdownServiceProperties, localeCode: string): Observable<ApplicationMarkdownExtension[]> {
        return this.markdownService.getDeclarationCountryMarkdown(
            properties.markdownId,
            localeCode
        );
    }
}
