/**
 * T REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: it_helpdesk@iru.org
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { DeclarationCreationURL } from '../model/declarationCreationURL';
// @ts-ignore
import { DeclarationSearchSort } from '../model/declarationSearchSort';
// @ts-ignore
import { SearchedDeclarations } from '../model/searchedDeclarations';
// @ts-ignore
import { SortOrder } from '../model/sortOrder';
// @ts-ignore
import { TransportOperationMessageExchange } from '../model/transportOperationMessageExchange';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class DeclarationService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Get T declaration creation url
     * @param transportOperatorId the identifier of the organisation for which we create T declaration
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDeclarationCreationURL(transportOperatorId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<DeclarationCreationURL>;
    public getDeclarationCreationURL(transportOperatorId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<DeclarationCreationURL>>;
    public getDeclarationCreationURL(transportOperatorId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<DeclarationCreationURL>>;
    public getDeclarationCreationURL(transportOperatorId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (transportOperatorId === null || transportOperatorId === undefined) {
            throw new Error('Required parameter transportOperatorId was null or undefined when calling getDeclarationCreationURL.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (transportOperatorId !== undefined && transportOperatorId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>transportOperatorId, 'transportOperatorId');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (default) required
        localVarCredential = this.configuration.lookupCredential('default');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/declarations/t/url`;
        return this.httpClient.request<DeclarationCreationURL>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the transport operations message exchange
     * @param guid the identifier of the t-declaration
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTOMessageExchange(guid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<TransportOperationMessageExchange>;
    public getTOMessageExchange(guid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<TransportOperationMessageExchange>>;
    public getTOMessageExchange(guid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<TransportOperationMessageExchange>>;
    public getTOMessageExchange(guid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (guid === null || guid === undefined) {
            throw new Error('Required parameter guid was null or undefined when calling getTOMessageExchange.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (default) required
        localVarCredential = this.configuration.lookupCredential('default');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/transit-declarations/${this.configuration.encodeParam({name: "guid", value: guid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/transport/operations/message-exchanges`;
        return this.httpClient.request<TransportOperationMessageExchange>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search for T declarations, sort by id desc (most recent first)
     * @param id the declaration id
     * @param transportOperatorId the identifier of transportOperator
     * @param submissionCountryCode a country, encoded as ISO3, where the predeclaration is sent to
     * @param mrn the mrn as allocated by customs authority
     * @param status the declaration status
     * @param from the start of search period
     * @param to the end of search (cannot exceed 1 month (epd.search.max.period&#x3D;P1M))
     * @param sort the element on which to sort
     * @param sortOrder the direction on which to sort
     * @param pageSize the number of requested results
     * @param pageAfterSortId the id (of element used to sort) just before the requested page of result
     * @param pageBeforeSortId the id (of element used to sort) just after the requested page of result
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchDeclarations(id?: string, transportOperatorId?: string, submissionCountryCode?: string, mrn?: string, status?: string, from?: string, to?: string, sort?: DeclarationSearchSort, sortOrder?: SortOrder, pageSize?: number, pageAfterSortId?: string, pageBeforeSortId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<SearchedDeclarations>;
    public searchDeclarations(id?: string, transportOperatorId?: string, submissionCountryCode?: string, mrn?: string, status?: string, from?: string, to?: string, sort?: DeclarationSearchSort, sortOrder?: SortOrder, pageSize?: number, pageAfterSortId?: string, pageBeforeSortId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<SearchedDeclarations>>;
    public searchDeclarations(id?: string, transportOperatorId?: string, submissionCountryCode?: string, mrn?: string, status?: string, from?: string, to?: string, sort?: DeclarationSearchSort, sortOrder?: SortOrder, pageSize?: number, pageAfterSortId?: string, pageBeforeSortId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<SearchedDeclarations>>;
    public searchDeclarations(id?: string, transportOperatorId?: string, submissionCountryCode?: string, mrn?: string, status?: string, from?: string, to?: string, sort?: DeclarationSearchSort, sortOrder?: SortOrder, pageSize?: number, pageAfterSortId?: string, pageBeforeSortId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (id !== undefined && id !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>id, 'id');
        }
        if (transportOperatorId !== undefined && transportOperatorId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>transportOperatorId, 'transportOperatorId');
        }
        if (submissionCountryCode !== undefined && submissionCountryCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>submissionCountryCode, 'submissionCountryCode');
        }
        if (mrn !== undefined && mrn !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>mrn, 'mrn');
        }
        if (status !== undefined && status !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>status, 'status');
        }
        if (from !== undefined && from !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>from, 'from');
        }
        if (to !== undefined && to !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>to, 'to');
        }
        if (sort !== undefined && sort !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sort, 'sort');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrder, 'sortOrder');
        }
        if (pageSize !== undefined && pageSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageSize, 'pageSize');
        }
        if (pageAfterSortId !== undefined && pageAfterSortId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageAfterSortId, 'pageAfterSortId');
        }
        if (pageBeforeSortId !== undefined && pageBeforeSortId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageBeforeSortId, 'pageBeforeSortId');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (default) required
        localVarCredential = this.configuration.lookupCredential('default');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/transit-declarations`;
        return this.httpClient.request<SearchedDeclarations>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
