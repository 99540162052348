import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApplicationFeatures, BusinessProfileType, Organisation } from '@api/index';
import { SettingsService } from '@api/t/index';
import { UserContextService } from '@app/core/services/user-context.service';
import { take } from 'rxjs/operators';
import { Feature, FeatureService } from '../../shared/services/feature.service';

@Injectable({
    providedIn: 'root',
})

export class NavigationService {

    private features: ApplicationFeatures;
    private etirAddendumIndicator: boolean;
    private isTAuthorizedPartyIndicator: boolean = false;
    private profilePublished: boolean;


    constructor(
        private featureService: FeatureService,
        private tSettingsService: SettingsService,
        public userContextService: UserContextService) {

        this.featureService.getFeatures().pipe(take(1)).subscribe(features => {
            this.features = features;
        });

        this.tSettingsService.getTAuthorizedPartyIndicator(null, 'response').subscribe((response: HttpResponse<any>) => {
            if (response.ok) {
                this.isTAuthorizedPartyIndicator = true;
            }
        });

        this.userContextService.organisation.subscribe(organisation => {
            if (organisation) {
                this.etirAddendumIndicator = organisation.haulierTIRAccess ? organisation.haulierTIRAccess.etirAddendumIndicator : false;
                this.profilePublished = organisation.businessProfile && organisation.businessProfile.publicationConsentIndicator;
            }
        });

    }

    getApplicationFeaturesList(): ApplicationFeatures {
        return this.features;
    }


    isMenuItemDisplayed(itemName: string): boolean {
        switch (itemName) {
            case 'epd.create':
                return this.userContextService.hasPermission('create-epd');

            case 'epd.list':
                return this.userContextService.hasPermission('read-epd');

            case 'epd.preference':
                return this.userContextService.hasPermission('update-epd');


            case 'epd.broker.support':
                return Organisation.TypeEnum.ThirdParty === this.userContextService.tenant.type
                    && BusinessProfileType.Broker === this.userContextService.businessProfileType;

            case 'companyinfo.generalinfo':
                return true;

            case 'companyinfo.search.business-profile':
                if (this.features != null && true === this.features[Feature.FeatureEnum.BusinessProfileEnabled]) {
                    if (this.userContextService.hasPermission('search-business-profile')) {
                        return (Organisation.TypeEnum.Iru === this.userContextService.tenant.type || this.profilePublished);
                    } else {
                        return false;
                    }
                }
                return false;

            case 'companyinfo.consignor-consignee':
                return this.userContextService.hasPermission('manage-trader');

            case 'companyinfo.vehicle':
                return this.userContextService.hasPermission('manage-vehicle');

            case 'companyinfo.driver':
                return this.userContextService.hasPermission('manage-driver');

            case 'manage.organisations':
                if (this.userContextService.hasPermission('manage-company-detail')) {
                    if ((Organisation.TypeEnum.ThirdParty === this.userContextService.tenant.type && BusinessProfileType.Broker === this.userContextService.businessProfileType)) {
                        //broker without the T right can't manage the company
                        return (this.features != null && true === this.features[Feature.FeatureEnum.tUseTobbUndGuarantee] && this.isTAuthorizedPartyIndicator);
                    } else {
                        return true;
                    }
                } else {
                    return false;
                }
            case 't.movement.declare':
                if (this.features != null && true === this.features[Feature.FeatureEnum.tUseTobbUndGuarantee] && this.isTAuthorizedPartyIndicator
                    && (BusinessProfileType.Broker === this.userContextService.businessProfileType)) {
                    return true
                }
                return false;

            case 't.movement.list':
                if (this.features != null && true === this.features[Feature.FeatureEnum.tUseTobbUndGuarantee] && this.isTAuthorizedPartyIndicator) {
                    return true
                }
                return false;
            case 't.declarations.new':
                return (this.features != null && true === this.features[Feature.FeatureEnum.tUseTobbUndGuarantee] && this.isTAuthorizedPartyIndicator && true === this.features[Feature.FeatureEnum.isAtezIntegrationEnabled]
                    && (Organisation.TypeEnum.ThirdParty === this.userContextService.tenant.type && BusinessProfileType.Broker === this.userContextService.businessProfileType));

            case 't.declarations.new.directLink':
                return (this.features != null && true === this.features[Feature.FeatureEnum.tUseTobbUndGuarantee] && this.isTAuthorizedPartyIndicator && true === this.features[Feature.FeatureEnum.isAtezIntegrationEnabled]
                    && (Organisation.TypeEnum.Haulier === this.userContextService.tenant.type || BusinessProfileType.TransportOperator === this.userContextService.businessProfileType));

            case 't.declarations.list':
                return (this.features != null && true === this.features[Feature.FeatureEnum.tUseTobbUndGuarantee] && this.isTAuthorizedPartyIndicator && true === this.features[Feature.FeatureEnum.isAtezIntegrationEnabled]
                    && (Organisation.TypeEnum.Iru === this.userContextService.tenant.type || Organisation.TypeEnum.Association === this.userContextService.tenant.type || Organisation.TypeEnum.Haulier === this.userContextService.tenant.type
                        || BusinessProfileType.Broker === this.userContextService.businessProfileType || BusinessProfileType.TransportOperator === this.userContextService.businessProfileType));
        }
        return false;
    }

    isMenuButtonDisplayed(itemName: string): boolean {
        if (this.userContextService.isDisabled()) {
            return false;
        }
        switch (itemName) {
            case 'home':
                return true;
            case 'order':
                return true;
            case 'epd':
                return this.isMenuItemDisplayed('epd.create')
                    || this.isMenuItemDisplayed('read-epd')
                    || this.isMenuItemDisplayed('update-epd')
                    || this.isMenuItemDisplayed('epd.list')
                    || this.isMenuItemDisplayed('epd.preference')
                    || this.isMenuItemDisplayed('epd.broker.support');
            case 'companyinfo':
                return this.isMenuItemDisplayed('companyinfo.generalinfo')
                    || this.isMenuItemDisplayed('companyinfo.search.business-profile')
                    || this.isMenuItemDisplayed('companyinfo.consignor-consignee')
                    || this.isMenuItemDisplayed('companyinfo.driver')
                    || this.isMenuItemDisplayed('companyinfo.vehicle');
            case 'manage.organisations':
                return this.isMenuItemDisplayed('manage.organisations');
            case 't':
                return this.isMenuItemDisplayed('t.movement.declare')
                    || this.isMenuItemDisplayed('t.movement.list')
        }
        return false;
    }

}