import { Component, OnInit, ViewChild } from '@angular/core';
import { ApplicationFeatures, BusinessProfileType, Organisation } from '@api/index';
import { DeclarationService } from '@api/t/index';
import { TDeclarationCountryPopUpMarkdownServiceProperties } from '@app/core/models/t-declaration-country-popup-markdown-properties';
import { UserContextService } from '@app/core/services/user-context.service';
import { BusinessOrganisationType } from '@app/modules/company/models/business-organisation-type-enum';
import { MarkdownComponent } from '@app/shared/components/markdown/markdown.component';
import { DeclarationCountryMarkdownService } from '@app/shared/services/declaration-country-popup-markdown-services';
import { Feature } from '@app/shared/services/feature.service';
import { OrganisationService as OrganisationSharedService } from "@app/shared/services/organisation.service";
import { TranslateService } from '@app/shared/services/translate.service';
import { WebApplicationConfigurationService } from '@app/shared/services/web-application-configuration.service';
import { LangChangeEvent } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { first } from "rxjs/operators";
import { NavigationService } from '../../shared/services/navigation.service';

@Component({
    selector: 'app-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

    public features: ApplicationFeatures;

    public userGuideUrl: string;
    public howToFillEpdUrl: string;
    public faqUrl: string;
    public contactUsUrl: string;

    public menuOrganisationLabel: string = "";
    public broker: boolean;
    private isIRU: boolean;
    private currentUserId: string;

    public orgTypes: BusinessOrganisationType.TypeEnum[] = [];

    @ViewChild(MarkdownComponent) markdownComponent: MarkdownComponent;
    showDeclarationMarkDownPopup = false;
    tDeclarationCountryPopupMarkdownServiceProperties: TDeclarationCountryPopUpMarkdownServiceProperties = new TDeclarationCountryPopUpMarkdownServiceProperties();

    constructor(
        private navigationService: NavigationService,
        public userContextService: UserContextService,
        private translateService: TranslateService,
        private declarationService: DeclarationService,
        private webApplicationConfigurationService: WebApplicationConfigurationService,
        public tDeclarationCountryMarkdownService: DeclarationCountryMarkdownService,
        private organisationSharedService: OrganisationSharedService) {

    }

    ngOnInit() {
        this.features = this.navigationService.getApplicationFeaturesList;

        forkJoin([
            this.organisationSharedService.getCurrentUserOrganisation().pipe(first()),
            this.webApplicationConfigurationService.getPropertiesValueByPrefix('user-guide.url'),
            this.webApplicationConfigurationService.getPropertiesValueByPrefix('how-to-fill-epd.url'),
            this.webApplicationConfigurationService.getPropertiesValueByPrefix('faq.url'),
            this.webApplicationConfigurationService.getPropertiesValueByPrefix('contact-us.url')
        ])
            .subscribe(([organisation, userGuideUrls, howToFillEpdUrls, faqUrls, contactUsUrls]) => {
                this.isIRU = organisation.type === Organisation.TypeEnum.Iru;
                this.currentUserId = organisation.party.id;
                this.setLinks(userGuideUrls, howToFillEpdUrls, faqUrls, contactUsUrls, this.translateService.currentLang);
                const businessProfileType = this.organisationSharedService.getBusinessProfileType(organisation);
                this.broker = businessProfileType === BusinessProfileType.Broker;
                const key = `menu.organisations.${businessProfileType}`;
                const specificMenuForCurrentOrganisation = this.translateService.instant(key);
                if (specificMenuForCurrentOrganisation !== key) {
                    this.menuOrganisationLabel = specificMenuForCurrentOrganisation;
                } else {
                    this.menuOrganisationLabel = this.translateService.instant('menu.organisations.default');
                }

                this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
                    this.setLinks(userGuideUrls, howToFillEpdUrls, faqUrls, contactUsUrls, event.lang);
                });

                if (this.features != null && true === this.features[Feature.FeatureEnum.BusinessOrganisationTypesShowAll]) {
                    this.orgTypes = Object.values(BusinessOrganisationType.TypeEnum);
                } else {
                    const accessActionTypeMap = this.organisationSharedService.getAccessActionMapForOrganisation(organisation);
                    if (accessActionTypeMap) {
                        this.orgTypes.push(...accessActionTypeMap.keys());
                    }
                }
            });

    }

    onMarkdownDialogClosed() {
        this.declarationService.getDeclarationCreationURL(this.currentUserId)
            .pipe(first())
            .subscribe((urlProperties) => {
                switch (urlProperties.target) {
                    case 'blank':
                        window.open(urlProperties.url, '_blank');
                        break;
                    default:
                        console.log('can\'t open url: ' + urlProperties.url);
                }
            }, error => {
                console.log(error);
            });
    }

    onDirectLinkToAtezClick() {
        if (this.showDeclarationMarkDownPopup) {
            //if it has been showen once already, show it again
            this.markdownComponent.refresh(this.tDeclarationCountryPopupMarkdownServiceProperties);
        } else {
            this.showDeclarationMarkDownPopup = true;
        }
    }


    private setLinks(userGuideUrls: Map<string, string>, howToFillEpdUrls: Map<string, string>, faqUrls: Map<string, string>, contactUsUrls: Map<string, string>, lang: string): void {
        if (userGuideUrls.has(`user-guide.url.${lang}`)) {
            this.userGuideUrl = userGuideUrls.get(`user-guide.url.${lang}`);
        } else {
            this.userGuideUrl = userGuideUrls.get('user-guide.url');
        }

        if (howToFillEpdUrls.has(`how-to-fill-epd.url.${lang}`)) {
            this.howToFillEpdUrl = howToFillEpdUrls.get(`how-to-fill-epd.url.${lang}`);
        } else {
            this.howToFillEpdUrl = howToFillEpdUrls.get('how-to-fill-epd.url');
        }

        if (faqUrls.has(`faq.url.${lang}`)) {
            this.faqUrl = faqUrls.get(`faq.url.${lang}`);
        } else {
            this.faqUrl = faqUrls.get('faq.url');
        }

        if (contactUsUrls.has(`contact-us.url.${lang}`)) {
            this.contactUsUrl = contactUsUrls.get(`contact-us.url.${lang}`);
        } else {
            this.contactUsUrl = contactUsUrls.get('contact-us.url');
        }
    }

    isMenuItemDisplayed(itemName: string): boolean {
        return this.navigationService.isMenuItemDisplayed(itemName);
    }

    isMenuButtonDisplayed(itemName: string): boolean {
        return this.navigationService.isMenuButtonDisplayed(itemName);
    }

    onMenuItemClick(featureName: string, ...args: string[]) {
        console.error('unexpected call to onMenuItemClick ' + featureName);
    }

    getMenuItemNameForOrgType(bOrgType: BusinessOrganisationType.TypeEnum) {
        if (this.isIRU && bOrgType === BusinessOrganisationType.TypeEnum.TransportOperator) {
            return 'organisation.business-organisation-type.non-tir-haulier';
        } else {
            return 'organisation.business-organisation-type.' + bOrgType;
        }
    }


}
